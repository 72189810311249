import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { graphql } from "gatsby";
import Trans, { t } from "../../components/Translate";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import Layout from "../../layouts/default";
import ScrollTable from "../../components/ScrollTable";
import Modal from "../../components/Modal";
import AppointmentBookingForm from "./components/AppointmentBookingForm";
import AboutContent from "../../components/AboutContent";
import { decimalToDateTime } from "../../util";
import "./styles.scss";
import Select, { components } from "react-select";

// import Cookies from "js-cookie";

const RESET_DEMO = gql`
  mutation resetDemo {
    resetDemo
  }
`;

const LoadingButton = ({ children, onClick, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <button
      {...rest}
      onClick={async event => {
        setIsLoading(true);
        return onClick(event)
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false));
      }}
      disabled={isLoading}
    >
      {children}
    </button>
  );
};
const SingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.chipLabel}
  </components.SingleValue>
); //https://codesandbox.io/s/5w60l5qmx?file=/src/index.js:209-231 // https://stackoverflow.com/questions/52482985/react-select-show-different-text-label-for-drop-down-and-control

// const Control = props => ({ children, ...rest }) => (
//   <components.Control {...rest}>
//     👍 {children} {console.log("v", rest)}
//   </components.Control>
// );

const selectlistStyles = {
  indicatorSeparator: () => {}, // removes the "stick"
  singleValue: ({ maxWidth, position, top, transform, ...otherStyles }) => ({
    ...otherStyles,
    color: "white"
  }),

  // singleValue: provided => ({
  //   ...provided,
  //   color: "white"
  // }),
  container: css => ({
    ...css,
    flexGrow: 1
  }),
  valueContainer: css => ({
    ...css,
    flexDirection: "row-reverse",
    marginLeft: "-5px",
    padding: "0px",
    color: "white"
  }),
  menu: (provided, state) => {
    // console.log("provided", provided);
    return {
      ...provided,
      textAlign: "left",
      width: "320px",
      fontSize: "14px"
    };
  },
  input: (provided, state) => {
    // console.log("provided", provided);
    return {
      ...provided,
      margin: "0px"
    };
  },
  dropdownIndicator: (base, state) => {
    let changes = {
      // all your override styles
      padding: "0px",
      color: "white"
    };
    return Object.assign(base, changes);
  },
  control: css => ({
    ...css,
    display: "inline-flex",
    backgroundColor: "transparent",
    border: "none",
    padding: "0px",
    minHeight: "auto",
    // width: "150px",
    boxShadow: "none",
    flexDirection: "row-reverse" //
  })
};

// Available => "Available" +  admin ? UserSelect[bookit] : <a>book it</a>
// Booked => admin ?  "Booked" + UserSelect[bookedUserName] :  (currentUserIsBooked ? 'You are booked(cancel)' : '')

const RoleAppointmentCell = ({
  role,
  me,
  users,
  propertyTurnoverDate,
  index
}) => {
  const [bookedRoleAppointment, setBookedRoleAppointment] = useState(
    role.appointment
  );
  //const [color, _setColor] = useState(bookedRoleAppointment ? "red" : "green");
  const color = bookedRoleAppointment ? "#F44336" : "#3AB457";
  useEffect(() => {
    setBookedRoleAppointment(role.appointment);
  }, [role.appointment]);
  // const bookedUser = bookedRoleAppointment ? role.appointment.user : null;
  // const isUsersRole = bookedUser && bookedUser.id === me.id;
  const bookItText = t("BOOK_IT");

  const currentUser = me ? me : {};
  const isAdministrator = currentUser.isAdministrator === "YES";

  return (
    <td
      key={index}
      className="text-center align-middle px-2 job-cell-td"
      style={{ backgroundColor: color }}
    >
      {bookedRoleAppointment ? (
        <>
          {isAdministrator ? (
            <div className="d-flex align-items-center job-cell">
              <UserSelect
                bookItText={bookItText}
                // components={{ SingleValue }}
                // styles={selectlistStyles}
                bookedRoleAppointment={bookedRoleAppointment}
                appointmentIdToCancel={bookedRoleAppointment.id}
                workerRole={role.workerRole.type}
                //bookedUser={bookedUser}
                turnoverDateId={propertyTurnoverDate.id}
                refetchAppointmentData={() => {}}
                onSuccess={propertyTurnoverAppointment => {
                  setBookedRoleAppointment(propertyTurnoverAppointment);
                }}
                value={{
                  value: bookedRoleAppointment.user.id,
                  chipLabel:
                    bookedRoleAppointment.user.firstName +
                    " " +
                    bookedRoleAppointment.user.lastName,
                  label:
                    bookedRoleAppointment.user.firstName +
                    " " +
                    bookedRoleAppointment.user.lastName
                }}
                options={users.map(user => {
                  return {
                    value: user.id,
                    chipLabel: user.firstName + " " + user.lastName,
                    label:
                      user.firstName +
                      " " +
                      user.lastName +
                      " " +
                      user.qualificationQuickview
                  };
                })}
              />
            </div>
          ) : (
            <div
              key={index}
              className="d-flex justify-content-center align-items-center text-white job-cell"
            >
              {bookedRoleAppointment.user.id === currentUser.id ? (
                <>
                  <div style={{ lineHeight: 1.1 }}>
                    <div className="text-white">
                      <Trans i18nKey={"YOU_ARE_BOOKED"} />
                    </div>

                    <CancelAppointmentButton
                      style={{ verticalAlign: "baseline" }}
                      turnoverAppointmentId={bookedRoleAppointment.id}
                      onSuccess={propertyTurnoverAppointment => {
                        setBookedRoleAppointment(null);
                      }}
                    />
                  </div>
                </>
              ) : (
                <Trans i18nKey={"BOOKED"} />
              )}
            </div>
          )}
        </>
      ) : (
        <div
          key={index}
          className="d-flex align-items-center justify-content-center job-cell"
        >
          {isAdministrator ? (
            <UserSelect
              bookItText={bookItText}
              // components={{ SingleValue }}
              // styles={selectlistStyles}
              // appointmentIdToCancel={role.appointment.id}
              bookedRoleAppointment={bookedRoleAppointment}
              workerRole={role.workerRole.type}
              //bookedRoleAppointment.user={bookedRoleAppointment.user}
              turnoverDateId={propertyTurnoverDate.id}
              refetchAppointmentData={() => {}}
              onSuccess={propertyTurnoverAppointment => {
                setBookedRoleAppointment(propertyTurnoverAppointment);
              }}
              value={{
                value: 0,
                chipLabel: bookItText
              }}
              options={users.map(user => {
                return {
                  value: user.id,
                  chipLabel: user.firstName + " " + user.lastName,
                  label:
                    user.firstName +
                    " " +
                    user.lastName +
                    " " +
                    user.qualificationQuickview
                };
              })}
            />
          ) : (
            <BookRoleAppointmentButton
              style={{ verticalAlign: "baseline" }}
              turnoverData={propertyTurnoverDate}
              role={role}
              userId={currentUser.id}
              me={me}
              // refetchAppointmentData={refetchAppointmentData}
              // turnoverDatesLoading={turnoverDatesLoading}
              onSuccess={propertyTurnoverAppointment => {
                console.log(
                  "propertyTurnoverAppointment",
                  propertyTurnoverAppointment
                );
                setBookedRoleAppointment(propertyTurnoverAppointment);
              }}
            />
          )}
        </div>
      )}
    </td>
  );
};

const CREATE_TURNOVER_APPOINTMENT = gql`
  mutation createPropertyTurnoverAppointment(
    $userId: Int!
    $turnoverDateId: Int!
    $workerRole: WorkerRoleTypeOption!
    $appointmentIdToCancel: Int
  ) {
    createPropertyTurnoverAppointment(
      data: {
        userId: $userId
        turnoverDateId: $turnoverDateId
        workerRole: $workerRole
        appointmentIdToCancel: $appointmentIdToCancel
      }
    ) {
      id
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
const CANCEL_TURNOVER_APPOINTMENT = gql`
  mutation cancelPropertyTurnoverAppointment($turnoverAppointmentId: Int) {
    cancelPropertyTurnoverAppointment(
      data: { turnoverAppointmentId: $turnoverAppointmentId }
    ) {
      id
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

const LoadingSpinner = ({ width, height }) => {
  return (
    <div
      className={`spinner-border loading-spinner`}
      role="status"
      style={{
        // color: "lighten(#ccc, 15%)",
        width: width || "100px",
        height: height || "100px"
      }}
    ></div>
  );
};

const CancelAppointmentButton = ({
  turnoverAppointmentId,
  refetchAppointmentData = () => {},
  onSuccess = () => {},
  style
}) => {
  const [mutationLoading, setMutationLoading] = useState(false);
  const [
    cancelAppointment,
    { data: cancelAppointmentData, loading: cancelLoading, error: cancelError }
  ] = useMutation(CANCEL_TURNOVER_APPOINTMENT);
  //
  return (
    <button
      style={style}
      disabled={mutationLoading}
      className="btn btn-link  p-0 text-white link-button"
      onClick={async () => {
        setMutationLoading(true);
        try {
          const { data } = await cancelAppointment({
            variables: {
              turnoverAppointmentId
            }
          });
          if (data.cancelPropertyTurnoverAppointment) {
            // const refetched = await refetchAppointmentData();
            onSuccess(data.cancelPropertyTurnoverAppointments);
            // if (refetched) {
            //   setMutationLoading(false);
            // }
            setMutationLoading(false);
          } else {
            setMutationLoading(false);
          }
        } catch (err) {
          setMutationLoading(false);

          if (err.message === "MINIMUM_CANCELLATION_NOTICE_NOT_MET") {
            err.message = t(`CANNOT_CANCEL_BOOKING_MESSAGE`); //TODO: Error codes from server need to be mapped and i18n friendly
          }
          alert(err.message);
          console.log("err", err);
        }
      }}
    >
      {mutationLoading ? (
        <LoadingSpinner width="18px" height="18px" />
      ) : (
        <>
          (<Trans i18nKey={"CANCEL"} />)
        </>
      )}
    </button>
  );
};

const isAuthorized = (me, workerRole) => {
  const isQualified = me.qualifiedWorkerRoles.find(
    role => workerRole === role.type
  )
    ? true
    : false;
  if (me.isAdministrator === "YES" || isQualified) {
    return true;
  }
  return false;
};
const BookRoleAppointmentButton = ({
  turnoverData,
  role,
  userId,
  // refetchAppointmentData,
  // turnoverDatesLoading,
  onSuccess = () => {},
  style,
  me
}) => {
  const [mutationLoading, setMutationLoading] = useState(false);

  const [
    createAppointment,
    { data: createAppointmentData, loading: createLoading, error: createError }
  ] = useMutation(CREATE_TURNOVER_APPOINTMENT);
  // const notAuthorizedMessage = t("NOT_AUTHORIZED_APPOINTMENT_MESSAGE");
  return (
    <button
      style={style}
      disabled={mutationLoading}
      className="btn btn-link text-center p-0 link-button text-white"
      onClick={async () => {
        const workerRole = role.workerRole.type;
        const turnoverDateId = turnoverData.id;

        if (!me || !isAuthorized(me, workerRole)) {
          alert(t("NOT_AUTHORIZED_APPOINTMENT_MESSAGE"));
          return;
        }
        // return;
        try {
          setMutationLoading(true);
          const { data: createdUserData } = await createAppointment({
            variables: {
              userId,
              turnoverDateId,
              workerRole
            }
          });
          if (createdUserData.createPropertyTurnoverAppointment) {
            // const refetched = await refetchAppointmentData();
            onSuccess(createdUserData.createPropertyTurnoverAppointment);
          }
          setMutationLoading(false);
        } catch (err) {
          setMutationLoading(false);
          if (err.message === "USER_IS_NOT_APPROVED") {
            err.message = t("NOT_AUTHORIZED_APPOINTMENT_MESSAGE");
          }
          alert(err.message);
          console.log("err", err);
        }
      }}
    >
      <>
        {mutationLoading ? (
          <>
            <LoadingSpinner width="18px" height="18px" />
          </>
        ) : (
          <>
            <Trans i18nKey={"BOOK_IT"} />
          </>
        )}
      </>
    </button>
  );
};

const UserSelect = ({
  children,
  bookedRoleAppointment,
  // appointmentIdToCancel,
  bookItText,
  workerRole,
  turnoverDateId,
  refetchAppointmentData,
  onSuccess = () => {},
  // value,
  ...rest
}) => {
  // const [valueState, setValueState] = useState(value);
  //console.log("bookedRoleAppointment", bookedRoleAppointment);
  const [
    createAppointment,
    { data: createAppointmentData, loading: createLoading, error: createError }
  ] = useMutation(CREATE_TURNOVER_APPOINTMENT);
  const [mutationLoading, setMutationLoading] = useState(false);

  // useEffect(() => {
  //   setValueState(value);
  // }, [value]);
  return (
    <Select
      isDisabled={mutationLoading}
      isLoading={mutationLoading}
      components={{ SingleValue }}
      styles={selectlistStyles}
      menuPortalTarget={document.querySelector("body")}
      onChange={async selected => {
        console.log("props", selected);
        const userId = Number(selected.value);

        try {
          setMutationLoading(true);
          const { data: createdAppointmentData } = await createAppointment({
            variables: {
              userId,
              turnoverDateId,
              workerRole,
              appointmentIdToCancel: bookedRoleAppointment
                ? bookedRoleAppointment.id
                : null
            }
          });
          console.log("createdAppointmentData", createdAppointmentData);
          if (createdAppointmentData.createPropertyTurnoverAppointment) {
            // const refetched = await refetchAppointmentData();
            onSuccess(createdAppointmentData.createPropertyTurnoverAppointment);
            //console.log("refetched", refetched);
            // if (refetched) {
            //   setMutationLoading(false);
            // }
            //setValueState(selected);
            setMutationLoading(false);
          }
        } catch (err) {
          setMutationLoading(false);
          alert(err.message);
          console.log("err", err);
        }

        // setValueState(selected);
      }}
      // value={valueState}
      {...rest}
      value={{
        value: bookedRoleAppointment ? bookedRoleAppointment.user.id : 0,
        chipLabel: bookedRoleAppointment
          ? bookedRoleAppointment.user.firstName +
            " " +
            bookedRoleAppointment.user.lastName
          : bookItText,
        label: bookedRoleAppointment
          ? bookedRoleAppointment.user.firstName +
            " " +
            bookedRoleAppointment.user.lastName
          : bookItText
      }}
      // options={usersData.users.map(user => {
      //   return {
      //     value: user.id,
      //     chipLabel:
      //       user.firstName + " " + user.lastName,
      //     label:
      //       user.firstName +
      //       " " +
      //       user.lastName +
      //       " " +
      //       user.qualificationQuickview
      //   };
      // })}
    />
  );
};

const Shifts = props => {
  const [resetDemo] = useMutation(RESET_DEMO);
  const [isWorkerDemo, setIsWorkerDemo] = useState(true);
  const [showAboutModel, setShowAboutModel] = useState(false);
  const { data: usersData, loading: usersLoading } = useQuery(
    gql`
      query Users {
        users(where: { status: ACTIVE }) {
          id
          firstName
          lastName
          emailAddress
          status
          isAdministrator
          qualificationQuickview
        }
      }
    `
  );
  const { data: meData, loading: meQueryLoading } = useQuery(gql`
    query Me {
      me {
        id
        firstName
        lastName
        emailAddress
        isAdministrator
        status
        qualifiedWorkerRoles {
          type
        }
      }
    }
  `);
  const {
    data,
    loading: turnoverDatesLoading,
    refetch: refetchAppointmentData
  } = useQuery(
    gql`
      query PropertyTurnoverDates {
        propertyTurnoverDates {
          id
          # start
          # end
          flexibilityEndDate
          dateTimeRange {
            startDate
            startTime
            endDate
            endTime
          }
          property {
            addressDisplay
            key
          }
          requiredWorkerRoles {
            workerRole {
              type
              abbreviation
            }
            appointment {
              id
              user {
                id
                firstName
                lastName
              }
            }
            # assignedWorker {
            #   firstName
            #   lastName
            # }
          }
        }
      }
    `,
    { fetchPolicy: "no-cache" }
  );

  const isQueryLoading = turnoverDatesLoading || meQueryLoading || usersLoading;

  // if (turnoverDatesLoading || meQueryLoading || usersLoading) {
  //   return (
  //     <div className="container pt-3">
  //       <div
  //         className="d-flex justify-content-center align-items-center"
  //         style={{ height: "80vh" }}
  //       >
  //         <LoadingSpinner />
  //       </div>
  //     </div>
  //   );
  // }
  // console.log("meData", meData);

  const { propertyTurnoverDates } = data || {};

  console.log("propertyTurnoverDates", propertyTurnoverDates);
  let userData = meData || { me: {} };
  const isAuthorized = userData.me?.status == "ACTIVE";
  const isAdministrator = userData.me && userData.me.isAdministrator === "YES";
  const [selectedCell, setSelectedCell] = useState(null);

  // const getWorkerRolelabel = (role, allRoles, index) => {
  //   const workerRole = role.workerRole || {};
  //   switch (workerRole.type) {
  //     case "CREW_LEADER":
  //       return "Crew leader";
  //     case "MAINTENANCE_OUTSIDE":
  //       return "MX & outside";
  //     case "CLEANING_INSIDE": {
  //       const foundInsideCleaners = allRoles
  //         .slice(0, index)
  //         .filter(filteredRole => {
  //           const workerRole = filteredRole.workerRole || {};
  //           return workerRole.type === "CLEANING_INSIDE";
  //         }).length;

  //       return `Inside #${foundInsideCleaners + 1}`;
  //     }
  //   }
  // };
  // let rowSpanCounter = 1;

  const FlexibleEndDateCell = ({ propertyTurnoverDate }) => {
    if (isAdministrator) {
      return (
        <button
          onClick={() => {
            setSelectedCell({
              key: propertyTurnoverDate.property.key,
              ...propertyTurnoverDate
            });
          }}
          className="btn btn-link d-flex align-items-center m-auto py-0"
        >
          <span className="mr-2">
            <b>{propertyTurnoverDate.property.key}</b>
          </span>
          <span className="edit-icon">
            <img src="/icon-edit.svg" />
          </span>
        </button>
      );
    }
    return (
      <div
        className="d-block position-relative"
        style={{ lineHeight: "11.5px" }}
      >
        <span className="mb-2 d-block">
          {propertyTurnoverDate.property.key}
        </span>
        <span className="small _position-absolute">
          <i>
            <Trans i18nKey={"FLEXIBLE_LOWERCASE"} />
          </i>
        </span>
      </div>
    );
  };

  const isDifferentIterationTimes = (prevTime, currentTime) => {
    if (
      prevTime?.startTime !== currentTime?.startTime ||
      prevTime?.endTime !== currentTime?.endTime
    ) {
      return true;
    }
    return false;
  };
  const { language } = useI18next();

  if (!isQueryLoading && !isAuthorized) {
    return (
      <Layout metaTitle={t("SHIFTS_META_TITLE")}>
        {props.location.search.includes("registration_complete=true") ? (
          <div className="container">
            <div
              className="alert alert-success mb-4"
              style={{ color: "black" }}
            >
              <p>
                <strong>
                  <Trans i18nKey={"REGISTRATION_SUCCESS"} />
                </strong>
              </p>
              <p>
                <Trans i18nKey={"REGISTRATION_THANK_YOU"} />
                <br /> <br />
                <Trans i18nKey={"REGISTRATION_THANK_YOU_DESCRIPTION"} />
              </p>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="d-flex align-items-center">
              <h1 className="content-header">
                <Trans i18nKey={"VIEW_SHIFTS"} />
                {/* {isAdministrator && <Trans>(Admin)</Trans>} */}
              </h1>
            </div>
            <div>
              {/* <p>
                <strong>Approval Pending</strong>
              </p> */}
              <p>
                <Trans i18nKey={"NOT_AUTHORIZED_SHIFT_MESSAGE"} />
              </p>
            </div>
          </div>
        )}
      </Layout>
    );
  }
  return (
    <Layout metaTitle={t("SHIFTS_META_TITLE")}>
      <div className="container" id="shifts-container">
        <div className="d-flex align-items-center">
          <h1 className="content-header">
            <Trans i18nKey={"VIEW_SHIFTS"} />
          </h1>
        </div>

        {!isAdministrator && (
          <p>
            <Trans i18nKey={"LEARN_MORE"} />{" "}
            <a href="#positions">
              <Trans i18nKey={"ABOUT_POSITIONS"} />
            </a>
          </p>
        )}
        {isQueryLoading ? (
          <div className="container pt-3">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <LoadingSpinner />
            </div>
          </div>
        ) : (
          <ScrollTable>
            <table className="shifts-table table _table-bordered  w-100">
              <thead>
                <tr className="text-center">
                  <th className="align-middle">
                    <Trans i18nKey={"SHIFT_DATE"} />
                  </th>
                  <td className="align-middle first-td">
                    <Trans i18nKey={"TIME"} />
                  </td>
                  <td className="align-middle">
                    <Trans i18nKey={"PROPERTY"} />
                  </td>
                  <td className="align-middle">
                    <Trans i18nKey={"CREW_LEADER"} />
                  </td>
                  <td className="align-middle">
                    <Trans i18nKey={"OUTDOOR"} />
                    <br />
                    <Trans i18nKey={"RECREATION_&"} />
                    <br />
                    <Trans i18nKey={"UPKEEP"} />
                  </td>
                  <td className="align-middle">
                    <Trans i18nKey={"INSIDE_1"} />
                  </td>
                  <td className="align-middle">
                    <Trans i18nKey={"INSIDE_2"} />
                  </td>
                  <td className="align-middle">
                    <Trans i18nKey={"INSIDE_3"} />
                  </td>
                </tr>
              </thead>
              <tbody>
                {propertyTurnoverDates &&
                  propertyTurnoverDates.map((propertyTurnoverDate, index) => {
                    const dateString = new Date(
                      `${propertyTurnoverDate?.dateTimeRange?.startDate}T00:00:00`
                    ).toLocaleString(language, {
                      month: "short",
                      weekday: "long",
                      day: "numeric"
                    });

                    const startTime = decimalToDateTime(
                      propertyTurnoverDate?.dateTimeRange?.startTime
                    );

                    const endTime = decimalToDateTime(
                      propertyTurnoverDate?.dateTimeRange?.endTime
                    );

                    const startMinuteDisplay = {};
                    const isStartHalfHour =
                      propertyTurnoverDate?.dateTimeRange?.startTime?.indexOf(
                        ".50"
                      ) !== -1;
                    if (isStartHalfHour) {
                      startMinuteDisplay.minute = "numeric";
                    }

                    const startTimeString = new Date(startTime).toLocaleString(
                      "en-US",
                      {
                        hour: "numeric",
                        ...startMinuteDisplay
                      }
                    );

                    const endMinuteDisplay = {};
                    const isEndHalfHour =
                      propertyTurnoverDate?.dateTimeRange?.startTime?.indexOf(
                        ".50"
                      ) !== -1;
                    if (isEndHalfHour) {
                      endMinuteDisplay.minute = "numeric";
                    }
                    const endTimeString = new Date(endTime).toLocaleString(
                      "en-US",
                      {
                        hour: "numeric",
                        ...endMinuteDisplay
                      }
                    );
                    const isLastIterationADifferentDate =
                      index !== 0 &&
                      propertyTurnoverDates[index]?.dateTimeRange?.startDate !=
                        propertyTurnoverDates[index - 1]?.dateTimeRange
                          ?.startDate;
                    const shouldIncludeInnerContent =
                      index == 0 || isLastIterationADifferentDate;

                    const isLastIterationADifferentTime = isDifferentIterationTimes(
                      propertyTurnoverDates[index - 1]?.dateTimeRange,
                      propertyTurnoverDates[index]?.dateTimeRange
                    );
                    const shouldIncludeTimeContent =
                      index === 0 ||
                      isLastIterationADifferentTime ||
                      isLastIterationADifferentDate;

                    return (
                      <tr key={index}>
                        <th
                          rowSpan={""}
                          className={`align-middle font-weight-normal text-nowrap ${
                            !shouldIncludeInnerContent
                              ? "empty-cell"
                              : "cell-has-content"
                          }`}
                          // className={`align-middle font-weight-normal cell-has-content`}
                        >
                          {shouldIncludeInnerContent ? dateString : ""}
                        </th>
                        <td
                          // className={`text-center align-middle ${
                          //   !shouldIncludeInnerContent
                          //     ? "empty-cell"
                          //     : "cell-has-content td-cell"
                          // }`}
                          className={`text-center align-middle text-nowrap first-td ${
                            !shouldIncludeTimeContent
                              ? "empty-cell"
                              : "cell-has-content td-cell"
                          }`}
                        >
                          {/* {shouldIncludeInnerContent ? (
                            <>
                              {startTimeString.toLowerCase().replace(/\s/, "")}{" "}
                              - {endTimeString.toLowerCase().replace(/\s/, "")}
                            </>
                          ) : (
                            ""
                          )} */}
                          {shouldIncludeTimeContent ? (
                            <>
                              {startTimeString.toLowerCase().replace(/\s/, "")}{" "}
                              - {endTimeString.toLowerCase().replace(/\s/, "")}
                            </>
                          ) : (
                            ""
                          )}
                        </td>

                        <td className="text-center align-middle position-relative">
                          {propertyTurnoverDate.flexibilityEndDate ? (
                            <FlexibleEndDateCell
                              propertyTurnoverDate={propertyTurnoverDate}
                            />
                          ) : (
                            <span>{propertyTurnoverDate.property.key}</span>
                          )}
                        </td>

                        {propertyTurnoverDate.requiredWorkerRoles.map(
                          (role, roleIndex) => {
                            return (
                              <RoleAppointmentCell
                                key={roleIndex}
                                role={role}
                                me={meData.me}
                                propertyTurnoverDate={propertyTurnoverDate}
                                users={usersData.users}
                              />
                            );
                          }
                        )}
                        {Array.from({
                          length:
                            5 - propertyTurnoverDate.requiredWorkerRoles.length
                        }).map((_, index) => (
                          <td key={index}></td>
                        ))}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </ScrollTable>
        )}

        <Modal
          title={
            <p>
              <Trans i18nKey={"PROPERTY"} /> {selectedCell?.key} -{" "}
              <Trans i18nKey={"UPDATE_DATE_AND_TIME"} />
            </p>
          }
          isOpen={selectedCell?.key ? true : false}
          onClose={() => setSelectedCell(null)}
        >
          {/* {console.log("selectedCell", selectedCell)} */}
          <AppointmentBookingForm
            turnoverDate={selectedCell}
            onClose={() => {
              setSelectedCell(null);
            }}
            onSuccess={id => {
              console.log("success!", id);
              refetchAppointmentData();
            }}
          />
        </Modal>

        <section className="mt-4">
          <p id="positions">
            <b>
              <Trans i18nKey={"POSITIONS"} />:
            </b>
          </p>
          <p className="mb-0">
            <b>
              <Trans i18nKey={"CL_CREW_LEADER"} />
            </b>
          </p>
          <p>
            <Modal
              isOpen={showAboutModel}
              title=""
              disableFocusTrap={true}
              onClose={() => setShowAboutModel(false)}
            >
              <AboutContent />
            </Modal>
            <Trans i18nKey={"CREW_LEADER_DESCRIPTION"} />
            <Link
              href="#"
              onClick={event => {
                event.preventDefault();
                setShowAboutModel(true);
              }}
            >
              <Trans i18nKey={"CLICK_HERE"} />
            </Link>
            )
          </p>
          <p className="mb-0">
            <b>
              <Trans i18nKey={"OR_OUTDOOR"} />
            </b>
          </p>
          <p>
            <Trans i18nKey={"PERSON_IS_RESPONSIBLE"} />
          </p>
          <p className="mb-0">
            <b>
              <Trans i18nKey={"IN_INSIDE"} />
            </b>
          </p>
          <p className="mb-0">
            {" "}
            <Trans i18nKey={"IN_INSIDE_DESCRIPTION"} />
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default Shifts;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
